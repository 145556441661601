import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/salesdepartmentukbranch',
    name: 'salesdepartmentukbranch',
    component: () => import(/* webpackChunkName: "about" */ '../views/SalesDepartmentUKbranch.vue'),
		children:[
			{
				path: 'ukbranddetail',
				name: 'ukbranddetail',
				component: () => import(/* webpackChunkName: "about" */ '../views/UKbranchDetail.vue')
			}
		]
  },
	{
	  path: '/qualification',
	  name: 'qualification',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Qualification.vue'),
		children:[
			{
				path: 'qualitydetail',
				name: 'qualitydetail',
				component: () => import(/* webpackChunkName: "about" */ '../views/QualityDetail.vue')
			}
		]
	},{
    path: '/latestbroadcast',
    name: 'latestbroadcast',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Latestbroadcast.vue'),
		children:[
			{
				path: 'latestdetail',
				name: 'latestdetail',
				component: () => import(/* webpackChunkName: "about" */ '../views/LatestDetail.vue')
			}
		]
  }, {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
  }, {
    path: '/technicaldetail',
    name: 'technicaldetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/TechnicalDetail.vue'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
	if(to.path!='/'){
		document.body.scrollTop = 800
		// firefox
		document.documentElement.scrollTop = 800
	}else{
		document.body.scrollTop = 0
		// firefox
		document.documentElement.scrollTop = 0
	}
  next()
})
export default router
