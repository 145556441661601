var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#FFFFFF","min-width":"1400px"}},[_c('div',{staticClass:"fl-s-c",staticStyle:{"min-width":"1400px","background-color":"#f8f8fa"}},[_c('div',{staticClass:"intelligence fl-s-c"},[_c('strong',{staticClass:"s-title"},[_vm._v(_vm._s(_vm.$t('m.Intelligence&Creation')))]),_c('div',{staticClass:"w-1 fr-b-c",staticStyle:{"margin-top":"3.125rem"}},_vm._l((_vm.menuItems),function(item,index){return _c('div',{key:index,staticClass:"fl-c pointer",on:{"click":function($event){return _vm.toTDetail(item.id,item.name,item.type)}}},[_c('img',{staticClass:"cover",staticStyle:{"border-radius":"50%","width":"87px","height":"87px"},attrs:{"src":item.url_image}}),_c('span',{staticClass:"menu-t"},[_vm._v(_vm._s(item.name))])])}),0)])]),_c('div',{staticClass:"sincerity fl-s-c"},[_c('span',{staticClass:"s-title"},[_vm._v(_vm._s(_vm.$t('m.Professionalintegrityoftechnicalservices')))]),_c('div',{staticClass:"w-1 fr-b-c",staticStyle:{"margin-top":"3.125rem"}},_vm._l((_vm.datanology),function(item,index){return (index<4)?_c('div',{key:index,staticClass:"sincerity-item fl-s-c"},[_c('div',{staticClass:"w-1 item-top fr-c"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-box relative"},[(item.content)?_c('div',{staticClass:"w-1 h-1",domProps:{"innerHTML":_vm._s(item.content)}}):_vm._e()]),_c('el-divider',{staticClass:"row-line"}),_c('router-link',{attrs:{"to":{
					path:item.type==null?'/':item.type=='table'?'/latestbroadcast':item.type=='list'?'/salesdepartmentukbranch':item.type=='page'?'/about':'/qualification',
					query:{
						pname:item.name,
						pid:item.id
					}
				}}},[_vm._v(_vm._s(_vm.$t('m.Viewdetails')))])],1):_vm._e()}),0)]),_c('div',{staticClass:"newProject relative fl-s-c"},[_c('img',{staticClass:"bgImage",attrs:{"src":require("@/assets/imgs/home/pro_bg.png")}}),_c('span',{staticClass:"s-title"},[_vm._v(_vm._s(_vm.$t('m.Latestprojectbroadcast')))]),_c('div',{staticClass:"relative pro-b-module fr-s-c"},_vm._l((_vm.databroad),function(item,index){return (index<4)?_c('div',{key:index,staticClass:"fl-s-c relative p-item"},[_c('p',{staticClass:"p-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"relative w-1",staticStyle:{"overflow":"hidden","font-size":"14px","height":"100%","padding":"0 25px","margin-top":"30px"}},[(item.content)?_c('div',{staticClass:"w-1 h-1",domProps:{"innerHTML":_vm._s(item.content)}}):_vm._e()]),_c('router-link',{staticClass:"fr-c p-detail",attrs:{"to":{
						path:item.type==null?'/':item.type=='table'?'/latestbroadcast':item.type=='list'?'/salesdepartmentukbranch':item.type=='page'?'/about':'/qualification',
						query:{
							pname:item.name,
							pid:item.id
						}
					}}},[_vm._v(_vm._s(_vm.$t('m.Viewdetails')))])],1):_vm._e()}),0)]),_c('div',{staticClass:"componyzz fl-s-c"},[_c('span',{staticClass:"s-title"},[_vm._v(_vm._s(_vm.$t('m.Companyqualification')))]),(_vm.datafication.length)?_c('div',{staticClass:"w-1 relative fr-b-c",staticStyle:{"margin-top":"50px"}},_vm._l((_vm.datafication),function(item,index){return _c('div',{key:index,staticClass:"fl-c pointer zz-item",on:{"click":function($event){return _vm.toDetail(item.id,item.title_name)}}},[_c('img',{staticClass:"zz-img",attrs:{"src":item.url_image}}),_c('span',{staticStyle:{"margin-top":"20px","font-size":"16px","color":"#555555"}},[_vm._v(_vm._s(item.title_name))])])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }