import http from '../http.js';
// 获取商品分类
function getGoodsType() {
    return http.get('/cates/index');
}
// 商品列表
function getDoodsList(data){
	return http.post('/good/getlist',data);
}
// 获取商品详情
function getGoodsDetails(data) {
    return http.post('/good/details',data);
}
// 生成订单
function generateOrder(data) {
    return http.post('/order/generate_order',data);
}
// 订单详情
function getOrderDetail(data) {
    return http.post('/order/getOrderinfo',data);
}
// 支付
function toPay(data) {
    return http.post('/payment/pay',data);
}

export default {
  getGoodsType,
	getGoodsDetails,
	getDoodsList,
	generateOrder,
	getOrderDetail,
	toPay
}