// 处理富文本
	const formatRichText = html=> {
		let newContent = html.replace(/<img[^>]*>/gi, function(match) {
			match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
			match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
			match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
			return match;
		});
		newContent = newContent.replace(/style="[^"]+"/gi, function(match) {
			match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi,
				'width:100%;');
			return match;
		});
		newContent = newContent.replace(/<br[^>]*\/>/gi, '');
		newContent = newContent.replace(/<img/gi,
			'<img style="display:block;margin-top:0;margin-bottom:0;"');
		return newContent;
	}
	// '<img style="width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"');
	export default formatRichText