import http from '../http.js';
// 轮播图
function getBanner(params) {
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.get(`/Valvedoor/banner`,{params});
}
// 底部关于我们
function getAbout(params) {
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.get(`/Valvedoor/aboutUs`,{params});
}
// 导航等
function getnavList(params){
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.get(`/Valvedoor/indexTitle`,{params});
}
// 分类列表
function getcList(params){
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.post(`/Valvedoor/categoryDetails`,params);
}

// 列表详情
function getDetail(params){
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.post(`/Valvedoor/productDetails`,params);
}
// 首页分类
function getHomeC(params){
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.get(`/Valvedoor/techNology`,{params});
}
// 首页分类
function getPageDetail(params){
	params.lang=localStorage.lang=='en'?'en':'zn'
	return http.get(`/Valvedoor/getProfile`,{params});
}


export default {
	getBanner,
	getAbout,
	getnavList,
	getcList,
	getDetail,
	getHomeC,
	getPageDetail
}
