import http from '../http.js';

// 地址列表
function addressList() {
	return http.get('/address/getlist');
}
// 修改默认地址
function changeDef(data) {
	return http.post('/address/setDefault', data);
}
// 添加地址
function addAddress(data) {
	return http.post('/address/save', data)
}
// 删除地址
function delAddress(data) {
	return http.post('/address/delete', data)
}
// 申请提现
function ApplayWithdrawl(data) {
	return http.post('/drawal/save', data)
}
// 提现列表
function getDrawal() {
	return http.get('/drawal/getlist')
}
// 收益明细
function getProfit() {
	return http.get('/profit/getlist')
}
// 获取验证码
function getVerificationCode(data) {
	return http.post('/login/sendCode', data)
}
// 上传图片
function upLoadsImage(data) {
	return http.post('/common/upload', data)
}

// 注册账号
function Register(data) {
	return http.post('/login/create_account', data)
}
// 账号密码登录
function Login(data) {
	return http.post('/login/account_login', data)
}
// 忘记密码
function forgetPwd(data) {
	return http.post('/login/upPwd', data)
}
// 修改信息  昵称 手机号 交易密码
/* 
		nickname		string	 	昵称
		mobile		string	 	手机号
		transaction_password	123456	string	 	交易密码 
*/
function upDateInfo(data) {
	return http.post('/index/upInfo', data)
}
// 设置登录密码
function setLoginPwd(data) {
	return http.post('/login/bindMobile_setPwd', data)
}
// 授权登录
function getAuth(data) {
	return http.post('/login/wx_authorization', data)
}
// 我的粉丝
function getFans(data) {
	return http.post('/index/son_users',data)
}
// 我的邀请
function getInviteInfo() {
	return http.get('/index/generate_qcode')
}
// 订单列表
function getOrderList(data) {
	return http.post('/order/getFansOrderlist', data)
}
// 用户信息
function getUserInfo() {
	return http.get('/index/getUserinfo')
}
// 收款信息
function getCollectionInfo() {
	return http.get('/collection/getInfo')
}
// 收款设置
function subCollectionInfo(data) {
	return http.post('/collection/bindInfo', data)
}
// 关于我们
function getAbout() {
	return http.get('/index/getAbout')
}
// 提现页面信息
function getDrawlPageInfo() {
	return http.get('/drawal/getConfig')
}
// 微信配置信息
function getWeXinConfig() {
	return http.get('/index/wxJssdkConfig')
}
// 获取上家绑定的信息
function getGoodCode(data) {
	return http.post('/good/getGoodCode', data)
}
// 上传支付凭证
function upPayInfo(data) {
	return http.post('/payment/upload_pay_voucher', data)
}
// 确认收款
function getMoney(data) {
	return http.post('/payment/enter_collection', data)
}
// 投诉订单
function subAppeal(data) {
	return http.post('/order/appeal', data)
}
// 取消投诉
function cancelAppeal(data) {
	return http.post('/order/excel_complaint', data)
}
// 委托详情
function entrustDetails(data) {
	return http.post('/order/entrust_details', data)
}
// 提交委托
function subEntrust(data) {
	return http.post('/order/entrust', data)
}
// 我的宝贝
function getMyGoods() {
	return http.get('/order/mygood')
}
// 申请提货
function subMyGoods(data) {
	return http.post('/order/extract',data)
}
// 商品上架
function putGood(data) {
	return http.post('/good/put_good',data)
}


export default {
	addressList,
	changeDef,
	addAddress,
	delAddress,
	getDrawal,
	getVerificationCode,
	upLoadsImage,
	Register,
	Login,
	forgetPwd,
	upDateInfo,
	ApplayWithdrawl,
	getAuth,
	setLoginPwd,
	getFans,
	getInviteInfo,
	getOrderList,
	getUserInfo,
	getProfit,
	getCollectionInfo,
	subCollectionInfo,
	getAbout,
	getDrawlPageInfo,
	getWeXinConfig,
	getGoodCode,
	getMoney,
	upPayInfo,
	subAppeal,
	entrustDetails,
	subEntrust,
	getMyGoods,
	subMyGoods,
	putGood,
	cancelAppeal
}
