/**
 * 数据请求的唯一入口
 */
import home from './modules/home.js';
import shop from './modules/shop.js';
import user from './modules/user.js';


export default {
    home,
		shop,
		user
}
